import { Inject, Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { State } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import dayjs from 'dayjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  BingoCards,
  CardsInterface,
  CheckAnswer,
  FinalResult,
  GameplayStatusEnum,
  GameplayStatusInterface,
  LoginPlayerResponse,
  OneAnswerStatistics,
  Player,
  PlayerAnswersInterface,
  PlayStateInterface,
  QuestionsBingoInterfaceOptionalAnswer,
  StatusAnswerInterface,
  TeacherStartGame,
} from './play.interfaces';
import { Role } from '@state/current-user/current-user.interfaces';
import {
  AnswerBingoDescriptionInterface,
  QuestionsBingoDescriptionInterface,
} from '../add-bingo/add-bingo.interfaces';
import { GameService } from '@pages/game/game.service';
import { LogoutService } from '@state/services/logout.service';
import { CancelGameService } from '@state/services/сancel-game.service';
import { PusherService } from '@state/services/pusher.service';
import { AuthState } from '@state/auth/auth.state';
import { TimerGameComponentService } from '@shared/game-components/components/timer/timer-game-component.service';

@Persistence()
@StateRepository()
@State({
  name: 'eng_play',
  defaults: {
    gameplay: {},
  },
})
@Injectable({
  providedIn: 'any',
})
@UntilDestroy()
export class PlayState extends NgxsDataRepository<PlayStateInterface> {
  private event$!: Subscription;

  constructor(
    private httpClient: HttpClient,
    private logoutService: LogoutService,
    @Inject(CancelGameService) private cancelGameService: CancelGameService,
    private router: Router,
    private pusherService: PusherService,
    private authState: AuthState,
    private zone: NgZone,
    @Inject(TimerGameComponentService) private timerGameComponentService: TimerGameComponentService,
    private gameService: GameService,
  ) {
    super();
    this.logoutService.logout$.pipe(untilDestroyed(this)).subscribe(() => this.reset());
  }

  @Computed()
  get role(): Role {
    return this.snapshot.role ?? Role.learner;
  }

  @Computed()
  get status(): GameplayStatusEnum {
    return this.snapshot?.gameplay?.status ?? GameplayStatusEnum.ClosedGame;
  }

  @Computed()
  get players(): Player[] {
    return this.snapshot?.gameplay?.players ?? [];
  }

  @Computed()
  get description(): QuestionsBingoDescriptionInterface {
    return this.snapshot?.gameplay?.question?.description ?? {};
  }
  @Computed()
  get answers(): AnswerBingoDescriptionInterface[] {
    return this.snapshot?.gameplay?.question?.answers ?? [];
  }

  @Computed()
  get answerTime(): number {
    return this.snapshot?.gameplay?.question?.answer_left ?? 0;
  }

  @Computed()
  get analysisTime(): number {
    return this.snapshot?.gameplay?.question?.analysis_left ?? 0;
  }

  @Computed()
  get answerStatistics(): OneAnswerStatistics[] {
    return this.snapshot?.gameplay?.answer_statistics ?? [];
  }

  @Computed()
  get finalResult(): FinalResult {
    return this.snapshot?.gameplay ?? null;
  }

  @Computed()
  get bingoCard(): BingoCards | null {
    return this.snapshot?.gameplay?.bingo_card ?? null;
  }

  @Computed()
  get cards(): CardsInterface {
    return this.snapshot?.gameplay?.cards ?? [];
  }

  @Computed()
  get playerAnswers(): PlayerAnswersInterface[] {
    return this.snapshot?.gameplay?.question?.player_answers ?? [];
  }
  get question(): QuestionsBingoInterfaceOptionalAnswer {
    return this.snapshot?.gameplay?.question ?? null;
  }

  @Computed()
  get questionNumber(): number {
    return (
      this.snapshot?.gameplay?.question?.number ?? this.snapshot?.gameplay?.question_number ?? 1
    );
  }

  @Computed()
  get statusAnswers(): StatusAnswerInterface[] {
    return [
      ...(this.snapshot?.statusAnswersRight ?? []),
      ...(this.snapshot?.statusAnswersWrong ?? []),
    ];
  }

  // TEACHER

  // @DataAction()
  start(gameId: number): Observable<TeacherStartGame> {
    this.ctx.setState((state) => ({ ...state, role: Role.teacher }));
    return this.httpClient.post<TeacherStartGame>(`{api}/gameplay/teacher/start/${gameId}`, {});
  }

  // @DataAction()
  play(): Observable<void> {
    return this.httpClient.post<void>(`{api}/gameplay/teacher/play`, {});
  }

  // @DataAction()
  cancel(): Observable<void> {
    return this.httpClient.post<void>(`{api}/gameplay/teacher/cancel`, {});
  }

  // @DataAction()
  skipAnalysis(): Observable<void> {
    return this.httpClient.post<void>('{api}/gameplay/teacher/skip-analysis', {});
  }

  // @DataAction()
  NextQuestion(): Observable<void> {
    return this.httpClient.post<void>(`{api}/gameplay/teacher/next-question`, {}).pipe(
      catchError((val) => {
        return of(val);
      }),
    );
  }

  // @DataAction()
  GameplayTeacher(): Observable<GameplayStatusInterface> {
    return this.httpClient.get<GameplayStatusInterface>(`{api}/gameplay/teacher`, {}).pipe(
      tap((data: GameplayStatusInterface) => {
        this.zone.run(() => {
          this.gameService.clear();
        });
        if (!this.snapshot.gameplay) {
          this.ctx.setState((state) => ({ ...state, gameplay: {} as GameplayStatusInterface }));
        }
        if (data.status === undefined || data.status === null) {
          return;
        }
        if (data.status === GameplayStatusEnum.WaitingRoom) {
          this.ctx.setState(
            patch({
              questionNumber: 1,
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                players: data.players,
                refused: data.refused,
              }),
            }),
          );
          this.router.navigate(['/game/lead/enter-game'], {
            queryParams: { pin: data.pin ?? '0000' },
            replaceUrl: true,
          });
          return;
        }
        if (data.status === GameplayStatusEnum.QuestionDescription) {
          this.timerGameComponentService.seconds = data.question?.analysis_left ?? 0;
          this.ctx.setState(
            patch({
              statusAnswersWrong: [] as StatusAnswerInterface[],
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                question: data.question,
              }),
            }),
          );
          this.router.navigate(['/game/lead/question-game'], { replaceUrl: true });
          return;
        }
        if (data.status === GameplayStatusEnum.QuestionAnswerTime) {
          this.timerGameComponentService.seconds = data.question?.answer_left ?? 0;
          this.ctx.setState(
            patch({
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                question: data.question,
              }),
            }),
          );
          this.router.navigate(['/game/lead/question-game'], { replaceUrl: true });
          return;
        }
        if (data.status === GameplayStatusEnum.AnswerStatistics) {
          this.ctx.setState(
            patch({
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                answer_statistics: data.answer_statistics,
              }),
            }),
          );
          this.router.navigate(['/game/lead/statistic-question'], { replaceUrl: true });
          return;
        }
        if (data.status === GameplayStatusEnum.FinalResult) {
          this.ctx.setState(
            patch({
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                pedestal: data.pedestal,
                other: data.other,
                cards: data.cards,
              }),
            }),
          );
          this.router.navigate(['/game/lead/game-over'], { replaceUrl: true });
          return;
        }
      }),
    );
  }

  // PLAYER

  @Computed()
  get getPlayer(): LoginPlayerResponse | null {
    return this.snapshot.player ?? null;
  }

  @Computed()
  get isAuthorizationExpired(): boolean {
    return this.getPlayer ? dayjs().isAfter(dayjs(this.getPlayer.expires_at)) : true;
  }

  @Computed()
  get authorization(): { Authorization: string } | object {
    return this.getPlayer?.access_token
      ? {
          Authorization: `Bearer ${this.getPlayer.access_token}`,
        }
      : {};
  }

  // @DataAction()
  login(gamePin: number, name: string): Observable<LoginPlayerResponse> {
    return this.httpClient
      .post<LoginPlayerResponse>(`{api}/gameplay/login/${gamePin}`, { name })
      .pipe(
        tap((player) => this.ctx.setState((state) => ({ ...state, player, role: Role.learner }))),
      );
  }

  // @DataAction()
  resetPlayer() {
    this.ctx.setState((state) => ({ ...state, player: null, role: Role.learner }));
  }

  // @DataAction()
  GameplayPlayer(): Observable<GameplayStatusInterface> {
    return this.httpClient.get<GameplayStatusInterface>(`{api}/gameplay`, {}).pipe(
      tap((data: GameplayStatusInterface) => {
        this.zone.run(() => {
          this.gameService.clear();
        });
        if (!this.snapshot.gameplay) {
          this.ctx.setState((state) => ({ ...state, gameplay: {} as GameplayStatusInterface }));
        }
        if (data.status === undefined || data.status === null) {
          return;
        }
        if (data.status === GameplayStatusEnum.ClosedGame) {
          this.cancelGameService.cancel();
          return;
        }
        if (data.status === GameplayStatusEnum.WaitingRoom) {
          this.ctx.setState(
            patch({
              questionNumber: 0,
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                players: data.players,
                refused: data.refused,
              }),
            }),
          );
          this.zone.run(() => {
            this.router.navigate(['/game/play/waiting-game'], { replaceUrl: true });
          });
          return;
        }
        if (data.status === GameplayStatusEnum.QuestionDescription) {
          this.timerGameComponentService.seconds = data.question?.analysis_left ?? 0;
          this.ctx.setState(
            patch({
              statusAnswersWrong: [] as StatusAnswerInterface[],
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                question: data.question,
              }),
            }),
          );
          this.zone.run(() => {
            this.router.navigate(['/game/play/question-game'], { replaceUrl: true });
          });
          return;
        }
        if (data.status === GameplayStatusEnum.QuestionAnswerTime) {
          this.timerGameComponentService.seconds = data.question?.answer_left ?? 0;
          this.ctx.setState(
            patch({
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                question: data.question,
                bingo_card: data.bingo_card,
              }),
            }),
          );
          this.zone.run(() => {
            this.router.navigate(['/game/play/question-game'], { replaceUrl: true });
          });
          return;
        }
        if (data.status === GameplayStatusEnum.AnswerStatistics) {
          this.ctx.setState(
            patch({
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                answer_statistics: data.answer_statistics,
              }),
            }),
          );
          this.zone.run(() => {
            this.router.navigate(['/game/play/statistic-question'], { replaceUrl: true });
          });
          return;
        }
        if (data.status === GameplayStatusEnum.FinalResult) {
          this.ctx.setState(
            patch({
              gameplay: patch<GameplayStatusInterface>({
                status: data.status,
                pedestal: data.pedestal,
                other: data.other,
                cards: data.cards,
              }),
            }),
          );
          this.zone.run(() => {
            this.router.navigate(['/game/play/game-over'], { replaceUrl: true });
          });
          return;
        }
      }),
    );
  }

  // @DataAction()
  SetAnswer(answerId: number): Observable<CheckAnswer> {
    return this.httpClient.post<CheckAnswer>(`{api}/gameplay/answer/${answerId}`, {}).pipe(
      tap((checkAnswer: CheckAnswer) => {
        const newStatus: StatusAnswerInterface[] = [
          {
            id: answerId,
            is_correct: checkAnswer.is_correct,
          },
        ];
        if (checkAnswer.is_correct) {
          this.ctx.setState((state) => ({
            ...state,
            statusAnswersRight: [...(state?.statusAnswersRight ?? []), ...newStatus],
          }));
        } else {
          this.ctx.setState((state) => ({
            ...state,
            statusAnswersWrong: [...(state?.statusAnswersWrong ?? []), ...newStatus],
          }));
        }
      }),
    );
  }

  // @DataAction()
  subscribeGameChanged(id: number, role: Role) {
    let authorization;
    if (role === Role.teacher) {
      authorization = this.authState.authorization;
    } else {
      authorization = this.authorization;
    }
    this.pusherService.subscribe(id, role, authorization);
    if (this.event$?.closed === false) {
      return;
    }
    this.event$?.unsubscribe();
    this.event$ = this.pusherService.event$
      .pipe(untilDestroyed(this))
      .subscribe((data: GameplayStatusInterface) => {
        if (data.status !== GameplayStatusEnum.QuestionDescription) {
          this.zone.run(() => {
            this.gameService.clear();
          });
        }
        if (!this.snapshot.gameplay) {
          this.ctx.setState((state) => ({ ...state, gameplay: {} as GameplayStatusInterface }));
        }
        if (data.status === undefined || data.status === null) {
          return;
        }
        if (role === Role.learner) {
          if (data.status === GameplayStatusEnum.ClosedGame) {
            this.cancelGameService.cancel();
            return;
          }
          if (data.status === GameplayStatusEnum.WaitingRoom) {
            this.ctx.setState(
              patch({
                questionNumber: 0,
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  players: data.players,
                  refused: data.refused,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/play/waiting-game'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.QuestionDescription) {
            this.timerGameComponentService.seconds = data.question?.analysis_left ?? 0;
            this.ctx.setState(
              patch({
                statusAnswersWrong: [] as StatusAnswerInterface[],
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  question: data.question,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/play/question-game'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.QuestionAnswerTime) {
            this.timerGameComponentService.seconds = data.question?.answer_left ?? 0;
            this.ctx.setState(
              patch({
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  question: data.question,
                  bingo_card: data.bingo_card,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/play/question-game'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.AnswerStatistics) {
            this.ctx.setState(
              patch({
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  answer_statistics: data.answer_statistics,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/play/statistic-question'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.FinalResult) {
            this.ctx.setState(
              patch({
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  pedestal: data.pedestal,
                  other: data.other,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/play/game-over'], { replaceUrl: true });
            });
            return;
          }
        }

        if (role === Role.teacher) {
          if (data.status === GameplayStatusEnum.WaitingRoom) {
            this.ctx.setState(
              patch({
                questionNumber: 1,
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  players: data.players,
                  refused: data.refused,
                }),
              }),
            );
            return;
          }
          if (data.status === GameplayStatusEnum.QuestionDescription) {
            this.timerGameComponentService.seconds = data.question?.analysis_left ?? 0;
            this.ctx.setState(
              patch({
                statusAnswersWrong: [] as StatusAnswerInterface[],
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  question: data.question,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/lead/question-game'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.QuestionAnswerTime) {
            this.timerGameComponentService.seconds = data.question?.answer_left ?? 0;
            this.zone.run(() => {
              this.ctx.setState(
                patch({
                  gameplay: patch<GameplayStatusInterface>({
                    status: data.status,
                    question: data.question,
                  }),
                }),
              );
              this.router.navigate(['/game/lead/question-game'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.AnswerStatistics) {
            this.ctx.setState(
              patch({
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  answer_statistics: data.answer_statistics,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/lead/statistic-question'], { replaceUrl: true });
            });
            return;
          }
          if (data.status === GameplayStatusEnum.FinalResult) {
            this.ctx.setState(
              patch({
                gameplay: patch<GameplayStatusInterface>({
                  status: data.status,
                  pedestal: data.pedestal,
                  other: data.other,
                }),
              }),
            );
            this.zone.run(() => {
              this.router.navigate(['/game/lead/game-over'], { replaceUrl: true });
            });
            return;
          }
        }
      });
  }

  // @DataAction()
  unsubscribeGameChanged(id: number, role: Role) {
    if (this.event$) {
      this.event$?.unsubscribe();
      this.pusherService.unsubscribe(id, role);
    }
  }

  // @DataAction()
  unsubscribeGameEvent() {
    this.event$?.unsubscribe();
  }

  // @DataAction()
  setRole(role: Role) {
    this.ctx.setState((state) => ({ ...state, role }));
  }

  clearData() {
    this.ctx.setState((state) => ({
      ...state,
      player: null,
      statusAnswersRight: [],
      statusAnswersWrong: [],
      gameplay: { ...state.gameplay, players: [] },
    }));
  }
}
