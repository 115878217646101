import {
  AnswerBingoDescriptionInterface,
  AnswerType,
  QuestionsBingoInterface,
} from '../add-bingo/add-bingo.interfaces';
import { Role } from '@state/current-user/current-user.interfaces';

export interface PlayStateInterface {
  questionNumber?: number;
  player: LoginPlayerResponse | null;
  gameplay: GameplayStatusInterface;
  statusAnswersRight: StatusAnswerInterface[];
  statusAnswersWrong: StatusAnswerInterface[];
  role: Role;
}

export interface TeacherStartGame {
  pin: number;
}

export enum GameplayStatusEnum {
  ClosedGame = 0,
  WaitingRoom = 1,
  QuestionDescription = 2,
  QuestionAnswerTime = 3,
  AnswerStatistics = 4,
  FinalResult = 5,
}

export interface WaitingRoom {
  id: number;
  pin: number;
  players: Player[];
  refused?: RefusedUserInterface;
}

export interface RefusedUserInterface {
  max_players_count_exceeds: boolean;
  max_students_per_game_count: number;
  name: string;
}

export interface Player {
  id: number;
  name: string;
  place?: number;
  score?: number;
  answers_count?: number;
}

type QuestionsBingoInterfaceKeys = Exclude<keyof QuestionsBingoInterface, 'answers' | 'id'>;

type QuestionsBingoInterfaceWithoutAnswers = Pick<
  QuestionsBingoInterface,
  QuestionsBingoInterfaceKeys
>;

export interface QuestionsBingoInterfaceOptionalAnswer
  extends QuestionsBingoInterfaceWithoutAnswers {
  answers?: AnswerBingoDescriptionInterface[];
  player_answers?: PlayerAnswersInterface[];
}

export interface QuestionDescription {
  question: QuestionsBingoInterfaceOptionalAnswer;
}

export interface QuestionAnswerTime extends QuestionsBingoInterfaceOptionalAnswer {
  bingo_card?: BingoCards;
  player_answers?: PlayerAnswersInterface[];
}

export interface PlayerAnswersInterface {
  player_name: string;
  name: string;
  answer: string;
  type: AnswerType;
  correctly_answered: boolean;
}

export interface BingoOneCard {
  id: number;
  answer: string;
  answered: boolean;
  type?: AnswerType;
}

export interface BingoCards {
  [key: string]: {
    [key: string]: BingoOneCard;
  };
}

export interface OneAnswerStatistics {
  answer: string;
  type: AnswerType;
  correctly_answered: boolean;
  players: Player[];
  players_count: number;
}

export interface AnswerStatistics {
  answer_statistics: OneAnswerStatistics[];
}

export type FilledCardType = boolean[][];

export interface CardsInterface {
  [key: string]: FilledCardType; // boolean[][];
}

export interface FinalOneResult extends Player {}
export interface FinalResult {
  pedestal: {
    [key: string]: Player[];
  };
  other: {
    [key: string]: Player[];
  };
  cards: CardsInterface;
}

export interface GameplayStatusInterface
  extends WaitingRoom,
    QuestionDescription,
    QuestionAnswerTime,
    FinalResult,
    AnswerStatistics {
  status: GameplayStatusEnum;
  question_number?: number;
}

export interface CheckAnswer {
  is_correct: boolean;
}

export interface LoginPlayerResponse {
  access_token: string;
  expires_at: string;
  player_id: number;
}

export interface StatusAnswerInterface {
  id: number;
  is_correct: boolean;
}
