import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CancelGameService {
  private cancelS = new Subject<any>();

  get cancel$(): Subject<any> {
    return this.cancelS;
  }

  constructor() {}

  cancel(): void {
    this.cancelS.next(null);
  }
}
