import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import Pusher from 'pusher-js';
// import Echo from 'laravel-echo';

import { environment } from 'environments/environment';
import { Role } from '@state/current-user/current-user.interfaces';
import { GameplayStatusInterface } from '@state/games/play/play.interfaces';
import { AuthState } from '@state/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  get event$(): Observable<GameplayStatusInterface> {
    return this._event;
  }

  private pusher: Pusher | undefined;
  private channel: any;
  // private echo!: Echo;
  private _event: Subject<GameplayStatusInterface> = new Subject<GameplayStatusInterface>();

  constructor(private authState: AuthState) {}

  subscribe(id: number, role: Role, authorization: { Authorization: string } | object) {
    // // @ts-ignore
    // if (!window.Pusher) {
    //   // @ts-ignore
    //   this.pusher = window.Pusher = Pusher;
    // }
    let authEndpoint = '';
    if (role === Role.learner) {
      authEndpoint = `${environment.apiUrl}/gameplay/broadcasting/auth`;
    } else {
      authEndpoint = `${environment.apiUrl}/gameplay/teacher/broadcasting/auth`;
    }

    if (!this.pusher) {
      this.pusher = new Pusher(environment.pusher.key, {
        cluster: environment.pusher.cluster,
        wsHost: environment.pusher.wsHost,
        wsPort: environment.pusher.wsPort,
        wssPort: environment.pusher.wsPort,
        forceTLS: environment.apiUrl.includes('https'),
        authEndpoint: authEndpoint,
        auth: {
          headers: {
            'X-CSRF-Token': '',
            'X-App-ID': environment.pusher.key,
            ...authorization,
          },
        },
      });
    } else {
      this.pusher.config.auth = {
        headers: {
          'X-CSRF-Token': '',
          'X-App-ID': environment.pusher.key,
          ...authorization,
        },
      };
    }

    if (!this.channel) {
      this.channel = this.pusher
        ?.subscribe(this.getNameChannelPrivate(id, role))
        .bind('Modules\\Gameplay\\Events\\GameChanged', (data: any) => {
          this._event.next(data);
        });
    } else {
      if (
        this.pusher
          .allChannels()
          .find((chanell) => chanell.name === this.getNameChannelPrivate(id, role))
      ) {
        return;
      }
      this.pusher.allChannels().map((chanell) => chanell.unsubscribe());
      this.channel = this.pusher
        ?.subscribe(this.getNameChannelPrivate(id, role))
        .bind('Modules\\Gameplay\\Events\\GameChanged', (data: any) => {
          this._event.next(data);
        });
    }
  }

  unsubscribe(id: number, role: Role) {
    console.log('unsubscribe');
    this.channel.unbind();
    this.pusher?.unsubscribe(this.getNameChannel(id, role));
    this.pusher?.disconnect();
    this.pusher = undefined;
  }

  getNameChannel(id: number, role: Role) {
    return role === Role.teacher ? `user.${id}` : `player.${id}`;
  }
  getNameChannelPrivate(id: number, role: Role) {
    return role === Role.teacher ? `private-user.${id}` : `private-player.${id}`;
  }
}
