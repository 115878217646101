import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private sendingS = new BehaviorSubject(false);
  private sendingAnswerS: BehaviorSubject<{ answerID: any; isSending: boolean }> =
    new BehaviorSubject<{ answerID: any; isSending: boolean }>({
      answerID: null,
      isSending: false,
    });

  get isSending$() {
    return this.sendingS;
  }

  get isSendingAnswer$() {
    return this.sendingAnswerS;
  }

  setSending(val: boolean) {
    this.sendingS.next(val);
  }

  setSendingAnswer(val: { answerID: any; isSending: boolean }) {
    this.sendingAnswerS.next(val);
  }

  clear() {
    this.sendingS.next(false);
    this.sendingAnswerS.next({
      answerID: null,
      isSending: false,
    });
  }
}
